// general imports
@import './general/reset';
@import './general/vars';

// mixins imports
@import './mixins/screens';
@import './mixins/uiElements';

// ui imports
@import './ui/containers';
@import './ui/default';

// component imports
@import './components/contact';
@import './components/nav';
@import './components/footer';


// page imports
@import './pages/home';
@import './pages/production';
@import './pages/detail';
@import './pages/about';
@import './pages/contactpage';

// general css
html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: 300;
  overflow-x: hidden;
  background-color: $bg-light;
  color: $bg_dark;

  // animation: fadein 0.5s linear;
}

::selection {
  color: $bg-light;
  background: $brand;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

main {
  position: relative;
}

h1 {
  color: $black;
}

h2 {
  @include h2;
  margin-bottom: 20px;
}

.pagecontent {
  opacity: 1;
  transform: translateY(0);

  transition: 0.3s ease-in-out;
}

.pagecontent.hidebody {
  opacity: 0;
  transform: translateY(150px);
}

@keyframes fadedown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(150px);
    display: none;
  }
}
