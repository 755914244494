.oro-footer {
  padding: 100px 30px;
  background-color: $bg_dark;

  row-gap: 50px;

  @include for-tablet-landscape-up {
  padding: 100px 0 50px 0;
    row-gap: 0;
  }

  &__link {
    display: inline-block;
    color: $white;
    text-decoration: none;
    margin-bottom: 15px;
    font-weight: 500;

    &:hover {
      color: $brand;
    }
  }

  &__extra {
    margin-top: 100px;
    color: #888;
    font-size: 18px;

    a {
      color: $white;
      opacity: 1;
    }
  }

  .fit-logo {
    margin-top: 20px;
  }
}