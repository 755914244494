.productionpage {
  .production-header {
    background-color: $bg_dark;
    padding: 50px 0 0 0;
  
    @include for-tablet-landscape-up {
      padding: 150px 0 0 0;
    }
  
    &__image-wrapper {
      max-width: 100%;
      overflow: hidden;
      margin-top: 60px;
      z-index: 6;
  
      @include for-desktop-up {
        margin-top: 0;
        transform: translateY(-150px);
      }
    }

    &__circle {
      display: block;
      position: absolute;

      top: 50px;
      right: -350px;

      width: 850px;
      height: 850px;
      opacity: .3;
  
      background-image: url('../images/circle.svg');
      background-size: contain;
      background-repeat: no-repeat;

      z-index: 5;
  
      @include for-tablet-landscape-up {
        opacity: .7;
      }
    }
  }
  
  .production {
    &-products {
      background-color: $bg_dark;
      padding: 50px 0 100px 0;
    
      &__title {
        text-align: center;
        margin-bottom: 80px;
      }
    
      &__list {
        display: flex;
        flex-direction: row;
  
        flex-wrap: wrap;
      }
    
      &__item {
        width: 100%;
        padding-right: 30px;
        margin-bottom: 60px;
    
        @include for-tablet-landscape-up {
          width: 29%;
        }
  
        @include for-desktop-up {
          padding-right: 50px;
        }
  
        img {
          width: 100%;
          margin-bottom: 25px;
        }
    
        &__desc {
          max-width: 80%;
        }
      }
    }
  
    &-applications {
      padding: 100px 0;
  
      @include for-tablet-landscape-up {
        padding: 150px 0;
      }
  
      &__title {
        margin-bottom: 100px;
      }
  
      &__list {
        display: flex;
        flex-wrap: wrap;
      }
  
      &__item {
        width: 30%;
        min-width: 260px;
        padding-right: 40px;
        margin-bottom: 90px;
  
        &__desc {
          margin-bottom: 25px;
        }
      }
    }
  
    &-cta {
      background-color: $brand;
      text-align: center;
      font-weight: 900;
      padding: 40px;
      margin-bottom: 20px;
  
      @include for-tablet-landscape-up {
        padding: 150px;
        margin-bottom: 200px;
      }
  
      &__text {
        font-weight: 700;
        margin: 0 auto;
        margin-bottom: 80px;
  
  
        @include for-desktop-up {
          font-weight: 900;
          max-width: 55%;
        }
      }
    }
  }
}