// all common ui elements (titles, body, buttons, links,...)
$pageTitleSize: 75px;
$pageTitleWeight: 900;
$pageTitleLineHeight: $pageTitleSize+20;

$h2Size: 55px;
$h2LineHeight: $h2Size+10;

$h3Size: 25px;
$h3LineHeight: $h3Size+10;

@mixin h1 {
  font-size: $pageTitleSize/2;
  font-weight: $pageTitleWeight;
  line-height: $pageTitleLineHeight/2;
  margin-bottom: $spacing;

  @include for-tablet-portrait-up {
    font-size: $pageTitleSize/1.5;
    line-height: $pageTitleLineHeight/1.5;
  }

  @include for-desktop-up {
    font-size: $pageTitleSize;
    line-height: $pageTitleLineHeight;
  }
}

@mixin h2 {
  font-size: $h2Size/1.2;
  font-weight: 700;
  line-height: $h2LineHeight/1.2;
  margin-bottom: $spacing/2;

  @include for-tablet-portrait-up {
    font-size: $h2Size;
    line-height: $h2LineHeight + 10px;
  }
}

@mixin h3 {
  font-size: $h3Size;
  font-weight: 700;
  color: $bg_dark;
  line-height: $h3LineHeight;
  margin-bottom: $h3LineHeight;

  @include for-tablet-portrait-up {
    font-size: $h3Size;
    margin-bottom: $h3LineHeight;
  }
}

@mixin body {
  font-size: 20px;
  line-height: 33px;
  color: $text-color;
  font-family: $body-font;
  font-weight: 200;
}

@mixin small {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: $bold;
  color: $brand;
}

@mixin button {
  display: block;
  background-color: $brand;
  padding: 12px 20px;
  color: $bg_dark;
  text-decoration: none;
  text-transform: lowercase;
  font-size: 20px;
  border-radius: 50px;
  font-weight: 500;

  width: auto;

  transition: $animation;

  &:hover {
    cursor: pointer;
    background-color: $white;
    color: $bg_dark;
  }
}

@mixin link {
  text-decoration: underline;
  color: $bg_dark;
  font-size: 22px;

  transition: 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: $brand;
  }
}

@mixin styledInput {
  font-family: $font-family;
  border: none;
  border-bottom: 1px solid $black;
  font-size: 20px;
  padding: 6px;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  margin-bottom: 30px;

  transition: $animation;

  &:focus,
  &:hover {
    outline: none;
    border-color: $brand;
  }
}

// classes linked to mixins

.oro-pagetitle {
  @include h1;
}

.oro-link {
  @include link;

  &--light {
    color: $white;

    &:hover {
      color: $brand;
    }
  }
}

.txt-body {
  @include body;
  letter-spacing: 1px;
}

.oro-h3, h3 {
  @include h3;
}

.oro-button {
  @include button;
  display: inline-block;

  &__light {
    background-color: $white;
    border: 2px solid $white;

    transition: .3s ease-in-out;

    &:hover {
      background-color: $brand;
      color: $white;
    }
  }
}

.color-light {
  color: #fff;
}

.wysiwyg {
  p {
    @include body;
    letter-spacing: 1px;
    max-width: 60ch;
    margin-bottom: 60px;
  }

  ul {
    list-style: disc;
    list-style-position: inside;
    margin-bottom: 15px;
  }

  ol {
    list-style-type: numbered;
    list-style-position: inside;
    margin-bottom: 15px;
  }

  h1 {
    @include h1;
  }

  h2 {
    @include h2;

    &:not(:first-child) {
      margin-top: 120px;
    }
  }

  h3, h4, h5, h6 {
    @include h3;
  }

  strong {
    font-weight: bold;
  }

  figure {
    width: 100%;
    max-height: 500px;
    overflow: hidden;

    margin-top: 100px;
  }

  img {
    width: 100%;
  }

  &--light {
    p, h1, h2, h3, h4, h5, h6 {
      color: $white;
    }
  }
}

// FORM STUFF

// form reset 
input,
textarea,
select {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: light;
  border: none;
}

// form styling
input:not([type="submit"]),
select,
textarea {
  @include styledInput;
}

.form {
  
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;

    &__radio {
      margin-right: 20px;
    }

    .inputLabel {
      display: flex;
      flex-direction: column;
      margin: 0 4rem 4rem 0;
      width: 100%;

      @include for-tablet-landscape-up {
        width: 45%;
      }

      .label {
        font-weight: 500;
      }
    }

    .message {
      margin-top: 3.5rem;
      width: 100%;
    }
  }
}