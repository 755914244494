.aboutpage {
  .about-header, .about-content {
    background-color: $bg_dark;
    padding: 50px 0 0 0;
  
    @include for-tablet-landscape-up {
      padding: 150px 0 0 0;
    }
  
    &__image-wrapper {
      max-width: 100%;
      overflow: hidden;
      margin-top: 60px;
  
      @include for-desktop-up {
        margin-top: 0;
        transform: translateY(-150px);
      }
    }
  }
  
  .about-content {
    padding: 50px 0 100px 0;
  
    @include for-tablet-landscape-up {
      padding: 50px 0 200px 0;
    }
  
    &__circle {
      position: absolute;
      left: -550px;
  
      width: 800px;
      height: 800px;
      opacity: .3;
  
      background-image: url('../images/circle.svg');
      background-size: contain;
      background-repeat: no-repeat;
  
      @include for-tablet-landscape-up {
        opacity: .7;
      }
    }
  }
  
  .about-cta {
    padding: 50px 0;
  
    @include for-tablet-landscape-up {
      padding: 150px 0;
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    &__text {
      font-weight: 700;
      text-align: center;
      margin-bottom: 60px;
  
      @include for-tablet-landscape-up {
        font-weight: 900;
        max-width: 60%;
      }
    }
  }
}