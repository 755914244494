.detail {
  &-header {
    background-color: $bg_dark;
    padding: 50px 0 50px 0;
  
    @include for-tablet-landscape-up {
      padding: 150px 0 100px 0;
    }

    &__image-wrapper {
      overflow: hidden;
      margin-top: 60px;

      &__img {
        width: 100%;
        height: auto;
      }
    }
  }

  &-content {
    padding: 100px 0;

    &__details {
      margin-bottom: 80px;
      
      img {
        max-width: 75%;
      }
    }

    &__order {
      position: sticky;
      top: 40px;

      background-color: $brand;
      padding: 30px;
      max-height: 400px;

      @include for-tablet-landscape-up {
        padding: 60px;
      }

      .oro-button {
        margin-top: 30px;
      }

      &__call {
        margin-top: 20px;
      }
    }
  }

  &-cta {
    background-color: $brand;
    text-align: center;
    font-weight: 900;
    padding: 40px;
    margin-bottom: 20px;

    @include for-tablet-landscape-up {
      padding: 150px;
      margin-bottom: 200px;
    }

    &__text {
      font-weight: 700;
      margin: 0 auto;
      margin-bottom: 80px;

      @include for-desktop-up {
        font-weight: 900;
        max-width: 55%;
      }
    }
  }
}