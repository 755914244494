.nav {
  padding: 30px 0 0 0;
  background-color: $bg_dark;
  z-index: 999;
  overflow: hidden;

  overflow-y: hidden;

  height: 100px;

  transition: .3s ease-in-out;

  &--active {
    height: 100vh;

    @include for-desktop-up {
      height: 100px;
    }
  }

  @include for-desktop-up {
    padding: 50px 0 0 0;
    overflow: initial;
  }

  &__logo {
    width: 200px;
  }

  &__icon {
    border: none;
    background-color: transparent;

    position: absolute;
    right: 20px;
    top: 55px;

    &__svg {
      transform: scale(1.4);
    }

    @include for-desktop-up {
      display: none;
    }
  }

  &__list {
    display: inline-block;
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 70px;

    @include for-tablet-landscape-up {
      height: 100px;
    }

    @include for-desktop-up {
      flex-direction: row;
      align-items: flex-start;
      position: relative;
      right: 60px;
      padding-right: 60px;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  &__link {
    display: inline-block;
    text-decoration: none;
    color: $white;

    transition: .2s ease-in-out;

    margin-bottom: 30px;
    font-size: 1.5em;

    @include for-desktop-up {
      margin-left: 20px;
      font-size: 22px;
    }

    &.active {
      position: relative;
      padding-bottom: 10px;

      &::after {
        content: "";

        display: block;
        position: absolute;
        bottom: 0px;
        left: 0;

        width: 100%;
        height: 2px;
        background-color: $white;
      }
    }

    &:hover {
      color: $brand;
    }
  }

  &__list__languages {
    display: flex;
    justify-content: space-between;
    width: 35%;

    margin-top: 50px;

    text-align: center;
    transition: .2s ease-in-out;

    @include for-desktop-up {
      position: absolute;
      right: 0;
      margin-top: 0;

      flex-direction: column;

      width: 25px;
      height: 25px;
      margin-left: 25px;
      overflow: hidden;

      border: 1.5px solid $white;
      padding: 7px 5px 3px 5px;
      border-radius: 50px;

      z-index: 99;
    
      transform: translateY(-8px);
    }

    &:hover {
      border-color: $brand;
      height: 89px;
    }

    &__link {
      display: inline-block;
      margin-bottom: 10px;
      color: $white;
      text-decoration: none;

      transition: .3s ease-in-out;

      &:hover {
        color: $brand;
      }
    }
  }
}