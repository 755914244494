.homepage {
  .scroll {
    display: none;

    @include for-desktop-up {
      display: initial;

      position: absolute;
      bottom: 130px;
      z-index: 99;
      
      transform: rotate(-90deg);
    }

    a {
      color: $brand;
      text-decoration: none;
      font-weight: 600;

      display: flex;
      align-items: center;

      transition: .3s ease-in-out;

      &:hover {
        color: $white;
      }
    }
  }

  .header {
    &__wrapper {
      background-color: $bg-dark;
      padding: 60px 0 0 0;

      @include for-tablet-landscape-up {
        padding: 100px 0;
      }
    }
  }

  .about {
    &__wrapper {
      position: relative;
      background-color: $bg_dark;
      padding: 50px 0 100px 0;

      @include for-desktop-up {
        padding: 0 0 150px 0;
      }
    }

    &-content__circle {
      display: block;
      position: absolute;

      top: 350px;
      right: -350px;

      width: 800px;
      height: 800px;
      opacity: .3;
  
      background-image: url('../images/circle.svg');
      background-size: contain;
      background-repeat: no-repeat;
  
      @include for-tablet-landscape-up {
        opacity: .7;
      }
    }

    &__image-wrapper {
      width: 100%;
      height: 30vw;
      min-height: 200px;
      max-height: 680px;
      overflow: hidden;

      margin-bottom: 80px;

      img {
        display: inline-block;
        width: 100%;
        height: auto;
      }
    }

    &__content {
      position: relative;
      width: 100%;

      @include for-tablet-landscape-up {
        width: 50%;
      }

      p {
        margin-bottom: 50px;
      }
    }

    &__links {
      .oro-link {
        display: inline-block;
        margin-right: 30px;
        margin-bottom: 30px;
      }
    }
  }

  .strengths {

    grid-column: 1/-1;

    @include for-tablet-landscape-up {
      grid-column: 2/-2;
    }
  
    &__wrapper {
      background: rgb(0,88,195);
      background: linear-gradient(180deg, rgba(0,88,195,1) 0%, rgba(0,88,195,1) 0%, rgba(57,57,58,1) 0%, rgba(57,57,58,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
      margin-bottom: 150px;
    }

    
    padding: 40px;

    background-color: $brand;

    @include for-tablet-landscape-up {
      padding: 100px;

    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px;

      &__item {
        width: 25%;
        min-width: 230px;
        margin-right: 5%;

        margin-bottom: 60px;

        @include for-tablet-landscape-up {
          margin-bottom: 90px;
        }
      }
    }
  }
}
