.oro-contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 100px 0 100px;

  @include for-tablet-landscape-up {
    padding: 100px 0 200px;
  }

  &__form {
    width: 100%;
    margin-bottom: 90px;

    @include for-desktop-up {
      width: 60%;
    }
  }

  &__card {
    display: inline-block;
    background-color: $brand;
    width: 100%;
    max-height: 400px;

    padding: 60px;

    @include for-desktop-up {
      width: 25%;
    }

    &__list {
      &__item {
        display: flex;
        margin-bottom: 35px;

        &__link {
          color: $bg_dark;
          transition: .3s ease-in-out;
          
          &:hover {
            color: $white
          }
        }

        svg {
          margin-right: 10px;
        }

        p {
          line-height: 25px;
        }
      }
    }
  }
}